// External Dependencies
import { WarningCircle } from "@phosphor-icons/react";
import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";

export default function RootErrorContent() {
  const error = useRouteError();

  useEffect(() => {
    console.error(error);
    Sentry.captureRemixErrorBoundaryError(error);
  }, [error]);

  let errorMessage = "Unknown error";
  if (error && typeof error === "object" && "message" in error) {
    errorMessage = error.message;
  }

  return (
    <div className="relative flex h-full w-full flex-col items-center px-8 pt-24 pb-12 text-center font-sans">
      <div className="absolute inset-0 bg-gradient-to-br from-gradientFaded-topLeft to-gradientFaded-bottomRight"></div>

      <div className="my-auto flex w-full flex-col items-center gap-6 rounded-xl bg-white px-10 py-8 drop-shadow-md sm:w-[440px]">
        <WarningCircle size={64} className="text-red-500" />
        <p className="text-sans mt-4 text-2xl font-semibold text-gray-900">
          Oops! Something went wrong.
        </p>
        {isRouteErrorResponse(error) ? (
          <>
            <p className="text-sans mt-2 text-lg text-gray-700">
              An error occurred while processing your request. Please try again
              later.
            </p>
            <p className="text-sans mt-2 text-sm text-gray-500">
              Status: {error.status} - {error.statusText || "Error"}
            </p>
          </>
        ) : (
          <p className="text-sans mt-2 text-sm text-gray-500">
            Error Details: {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
}
